import './Footer.scss'

function Footer() {
  return (
    <div className="footerContainer">
      <p>designed & built by Alana Calvo</p>

    </div>
  )
}

export default Footer